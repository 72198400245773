<script>
export default {
  name: 'ShipmentTracking',
  data () {
    return {
      tracking_number: '',
      statuses: [],
      loading: false,
      fetched: false
    }
  },
  created () {
    if (this.$route.query.tracking_number) {
      this.tracking_number = this.$route.query.tracking_number
      this.fetch()
    }
  },
  methods: {
    fetch () {
      if (this.tracking_number === '' || this.tracking_number == null) {
        return
      }
      this.loading = true
      this.axios.get(`/v2/shipment-tracking?tracking_number=${this.tracking_number}`)
        .then(response => {
          this.statuses = response.data
          this.loading = false
          this.fetched = true
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    }
  }
}
</script>

<template>
  <div class="mt-4 mb-4">
    <b-field label="Enter Shipment Number...">
      <b-input
        v-model="tracking_number"
        placeholder="Shipment Number..."
        type="search"
      />
      <p class="control">
        <b-button
          class="button is-success"
          @click="fetch"
        >
          Track
        </b-button>
      </p>
    </b-field>

    <div class="mt-5">
      <div v-if="fetched && statuses.success">
        <div class="timeline">
          <header class="timeline-header">
            <span class="tag is-small is-success">Latest</span>
          </header>
          <div class="timeline-item" v-for="status in statuses.statuses" :key="status.id">
            <div class="timeline-marker is-icon">
              <b-icon
                icon="chevron-up"
                size="is-small">
              </b-icon>
            </div>
            <div class="timeline-content">
              <p class="heading">{{ status.date || status.created_at | formatDateLocale }}</p>
              <p>{{ status.title }} <span v-if="status.description">- {{ status.description }}</span> <span v-if="status.remarks">- {{ status.remarks }}</span> </p>
              <p v-if="status.location">
                <b-icon
                  icon="map-marker"
                  size="is-small">
                </b-icon>
                {{ status.location }}
              </p>
            </div>
          </div>
          <div class="timeline-header">
            <span class="tag is-small is-success">Oldest</span>
          </div>
        </div>
      </div>
      <div v-if="fetched && !statuses.success">
        <b-notification
          type="is-warning is-light"
          role="alert">
          We could not find any status for your shipment. If you are sure the Shipment Number entered is correct, please check back later.
        </b-notification>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
